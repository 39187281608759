html,
body,
#root {
  height: 100%;
}

.navbar-nav:last-child .nav-item:last-child .dropdown-toggle {
  padding-right: 0;
}

.table-call tbody {
  cursor: pointer;
}

.call-person {
  border-top: 1px solid #dee2e6;
  cursor: pointer;
}
.call-person:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-expand-md .navbar-nav {
  flex-direction: row !important;
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
}
.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
